.page{
width: 100%;
height: 100%;
margin: 0;
display : flex;
flex-direction: column;
background-color: snow;
/* display: grid;
grid-auto-rows: var(--page-header-height) 1fr; */
}

.pageBody{
position: relative;
width: 100%;
height: 100%;
background-color: snow;
display: flex;
flex-direction: column;
overflow: auto;
}

.scrollContainer {
    height: calc(100vh - 72px);
    overflow: auto;
}

