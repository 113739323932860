.listStyle{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 35px;
}

.ulContainer{
    list-style: none;
    margin:  2px 0;
    padding: 0;
    width: 250px;
    border-radius: 5px;
}

.listStyle:hover{
    background-color: aliceblue;
    cursor: pointer;
}