body {
  background-color: rgb(245, 245, 245) !important;
  width: 100vw;
  height: 100vh;
  font-family: "Shell Font", sans-serif;
}

.App {
  background-color: rgb(245, 245, 245) !important;
}

.AppNavigation div ul li {
  font-family: "Shell Font", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}

.AppNavigation h1 {
  font-family: "Shell Font", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}

table.exchangesTable th+th+th div {
  text-align: right;
  display: block;
  padding-right: 20px;
}

.exchangesTable tbody tr:nth-of-type(even) {
  background-color: rgba(171, 171, 171, 0.09);
}

div.shell-select-dropdown {
  z-index: 55 !important;
}

div.drawer-box {
  font-family: "Shell Font", sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0em;
  font-style: normal;
  gap: 12px;
  border-bottom: 1px solid rgba(28, 28, 28, 0.14);
  z-index: 10;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  min-height: 40px;
  padding-right: 20px;
  padding-left: 20px;  
}

#rcDialogTitle1, #rcDialogTitle3 {
  border-bottom: 1px solid #ccc;
}

div:has(> ol.editTableStepper) {
  padding-bottom: 0px;
  padding-top: 0px;
}